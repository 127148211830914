<template>
  <div>
    <div class="propety">
      <div style="min-height: 600px">
        <el-form :model="data" ref="ListingForm" :rules="rules">
          <div class="pages">
            <div class="form_in">

              <!-- General Information  -->
              <div>
                <div class="top-prodect">
                  <span class="title-text">Amenities</span>
                </div>
                <div class="check-row row">
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.air_conditioning">Air Conditioning</el-checkbox>
                    <div v-show="data.air_conditioning" class="show-margin">
                      <el-select v-model="data.ac_types" multiple placeholder="A/C Type">
                        <el-option v-for="item in acTypeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.bbq_tool">BBQ Tools</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.beach_chair">Beach Chairs</el-checkbox>
                    <div v-show="data.beach_chair" class="show-margin">
                      <el-select v-model="data.beach_chair_quantity" placeholder="Quantity">
                        <el-option v-for="item in 10" :key="item" :label="item" :value="item">
                        </el-option>
                        <el-option label="10+" value="10+"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.beach_towel">Beach Towels</el-checkbox>
                    <div v-show="data.beach_towel" class="show-margin">
                      <el-select v-model="data.beach_towel_quantity" placeholder="Quantity">
                        <el-option v-for="item in 10" :key="item" :label="item" :value="item">
                        </el-option>
                        <el-option label="10+" value="10+"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.beach_umbrella">Beach Umbrella</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.bicycle">Bicycles</el-checkbox>
                    <div v-show="data.bicycle" class="show-margin">
                      <el-form-item>
                        <el-select  v-model="data.bicycle_quantity"
                          placeholder="Quantity">
                          <el-option v-for="(item, index) in 100" :key="index" :value="index">{{ index }}</el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.blender">Blender</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.bluetooth_speaker">Bluetooth Speakers</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.book">Books</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.coffee_maker">Coffee Maker</el-checkbox>
                    <div v-show="data.coffee_maker" class="show-margin">
                      <el-form-item>
                        <p class="propety-title">Coffee Maker Type</p>
                        <el-select v-model="data.coffee_maker_type" style="margin-top: 10px !important;"
                          placeholder="Please choose" no-data-text="No data">
                          <el-option v-for="item in coffeeMakerTypeList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.cooler">Cooler</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.counter_seating">Counter Seating</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.deck">Deck</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.dining_area">Dining Area</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.dining_room">Dining Room</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.dishwasher">Dishwasher</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.dish_or_cup_or_utensil">Dishes,Cups & Utensils</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.clothes_dryer">Dryer (clothes)</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.dvd_player">DVD player</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.fan">Fans</el-checkbox>
                    <div v-show="data.fan" class="show-margin">
                      <el-select v-model="data.fan_quantity" placeholder="Quantity">
                        <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.fireplace">Fireplaces (working)</el-checkbox>
                    <div v-show="data.fireplace" class="show-margin">
                      <el-select v-model="data.fireplace_quantity" placeholder="Quantity">
                        <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.gym">Fitness Room / Gym</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.food_processor">Food Processor</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.grill">Grill / BBQ</el-checkbox>
                    <div v-show="data.grill" class="show-margin">
                      <el-form-item>
                        <p class="propety-title">Grill Type</p>
                        <el-select v-model="data.grill_type" style="margin-top: 10px !important;"
                          placeholder="Please choose">
                          <el-option v-for="item in grillTypeList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.hair_dryer">Hair Dryer</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.heating">Heating</el-checkbox>
                    <div v-show="data.heating" class="show-margin">
                      <el-form-item>
                      <p class="propety-title">Heating Type</p>
                      <el-select v-model="data.heating_type" style="margin-top: 10px" placeholder="Please choose">
                        <el-option v-for="item in heatingTypeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    </div>
                  </div>

                  <div class="checkbox-item">
                    <el-checkbox v-model="data.hot_tub_or_spa">Hot Tub / Spa</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.ice_maker">Ice Maker</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.ice_trays">Ice Trays</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.iron">Iron</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.ironing_board">Ironing Board</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.jacuzzi">Jacuzzi</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.lawn_or_garden">Lawn / Garden</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.linen">Linens Provided</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.lobster_pot">Lobster Pot</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.lobster_utensil">Lobster Utensils</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.microwave">Microwave</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.outdoor_dining_area">Outdoor Dining Area</el-checkbox>
                    <el-form-item prop="seating" :rules="rules.required">
                      <p class="propety-title">Seating for</p>
                      <el-select style="margin-top: 10px !important;" v-model="data.seating"
                        placeholder="Please choose">
                        <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.outdoor_furniture">Outdoor Furniture</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.outdoor_shower">Outdoor Shower</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.oven">Oven</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.parking">Parking Off-Street </el-checkbox>
                    <div v-show="data.parking" class="show-margin">
                      <el-select v-model="data.parking_quantity" placeholder="Quantity">
                        <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.patio">Patio</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.pool">Pool</el-checkbox>
                    <div v-show="data.pool" class="show-margin">
                      <el-form-item>
                        <p class="propety-title">Pool Type</p>
                        <el-select style="margin-top: 10px !important" v-model="data.pool_type"
                          placeholder="Please choose">
                          <el-option v-for="item in poolTypeList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.porch">Porch</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.pot_or_pan">Pots and Pans</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.refrigerator">Refrigerator</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.safe">Safe</el-checkbox>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.stove">Stove</el-checkbox>
                    <div v-show="data.stove" class="show-margin">
                      <el-form-item>
                        <p class="propety-title">Stove Type</p>
                        <el-select style="margin-top: 10px !important;" v-model="data.stove_type"
                          placeholder="Please choose" no-data-text="No data">
                          <el-option v-for="item in stoveTypeList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="checkbox-item">
                    <el-checkbox v-model="data.tea_kettle">Tea Kettle</el-checkbox>
                  </div>
                  <div class="checkbox-item" style="height: 50px;">
                    <el-checkbox v-model="data.tennis_court">Tennis Court</el-checkbox>
                    <div v-show="data.tennis_court" class="show-margin">
                      <el-form-item>
                        <p class="propety-title" style="top: -20px !important">Tennis Court Type</p>
                        <el-select v-model="data.tennis_court_type" placeholder="Please choose">
                          <el-option v-for="item in tennisCourtType" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item" style="height: 50px;">
                    <el-checkbox v-model="data.toaster">Toaster</el-checkbox>
                    <div v-show="data.toaster" class="show-margin">
                      <el-form-item>
                        <p class="propety-title">Toaster Type</p>
                        <el-select style="margin-top: 8px !important;" v-model="data.toaster_type"
                          placeholder="Please choose" no-data-text="No data">
                          <el-option v-for="item in toastTypeList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.towel">Towels Provided</el-checkbox>
                  </div>
                  <div class="checkbox-item" style="width: 680px !important; ">
                    <el-checkbox v-model="data.tv">TV</el-checkbox>
                    <div style="display: flex; flex-direction: row; gap: 10px;">
                      <div class="show-margin" v-show="data.tv">
                        <p class="propety-title">TV Service</p>
                        <el-select style="margin-top: 10px !important;" v-model="data.tv_service"
                          placeholder="Please choose">
                          <el-option v-for="item in tvServiceList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="show-margin" v-show="data.tv">
                        <el-select style="margin-top: 10px !important;" v-model="data.tv_quantity" placeholder="Quantity">
                          <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <div class="checkbox-item">
                    <el-checkbox v-model="data.washing_machine">Washing Machine</el-checkbox>
                  </div>
                  <div class="checkbox-item" style="width: 650px !important">
                    <el-checkbox v-model="data.wifi">Wifi</el-checkbox>
                    <div style="display: flex; flex-direction: row; gap: 10px;">
                      <div v-show="data.wifi" class="show-margin">
                        <p class="propety-title">Wifi Network</p>
                        <el-input style="margin-top: 13px !important;" v-model="data.wifi_network_name" ></el-input>
                      </div>
                      <div v-show="data.wifi" class="show-margin">
                        <el-form-item prop="wifi_password">
                          <p class="propety-title">Wifi Password</p>
                          <el-input style="margin-top: 13px !important;" v-model="data.wifi_password" >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 选择类型 -->
        </el-form>
      </div>
      <div class="btn-save">
        <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading">
          <p class="row btn-row">
            <span>Save/Update</span><i class="el-icon-right"></i>
          </p>
        </el-button>
      </div>
    </div>
    <!-- 保存的按钮 -->
    <div class="propety_save" @click="save" v-if="btnFlag">
      <el-tooltip class="item" effect="dark" content="Save" placement="bottom">
        <img src="../../assets/img/save.png" alt />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PropertyWeek from "./Property/PropertyWeek"
import { apiGetListing, apiCreateListing, apiUpdateListing, apiPublishListing, apiHideListing, apiDeactivateListing, apiUploadFile } from "../../API/api";
import { Minixs } from "../../js/mixins";
export default {
  components: { PropertyWeek },
  mixins: [Minixs],
  data() {
    return {
      loadingFile: false,
      deactivated_reason: "",
      fullscreenLoading: false,
      btnFlag: true,
      fileName: "Attached Files", // 文件名
      fileUrl: "", // 文件访问地址
      action: "hide",
      map: {}, // 用来存放房间和浴室的对应关系
      data: {


      },
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      availabilities: [],
    };
  },
  methods: {
    ...mapActions([
      "getAreas",
      "getPropertyPriorityList",
      "getACTypeList",
      "getCompanyList",
      "getHeatingTypeList",
      "getTVServiceList",
      "getCoffeeMakerTypeList",
      "getStoveTypeList",
      "getToastTypeList",
      "getGrillTypeList",
      "getPoolTypeList",
      "getFloorLevelList",
      "getBedTypeList",
      "getBathroomTypeList",
      "getTennistCourtList",
    ]),
    selectAction(val) {
      if (val === "deactivate") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    // 修改Action动作
    changeAction(res) {
      if (res.deleted_at) {
        this.action = "deactivate";
        this.deactivated_reason = res.deactivated_reason;
        this.showReason = true;
      } else if (res.publish) {
        this.action = "publish";
        this.showReason = false;
      } else {
        this.action = "hide";
        this.showReason = false;
      }
    },
    // 数据格式转换
    changeDate(val) {
      let res = Object.assign({}, val)
      // 转换枚举类型
      if (res.occupancy_tax_file) {
        this.fileName = res.occupancy_tax_file.file_name;
        this.fileUrl = res.occupancy_tax_file.url;
        res.occupancy_tax_file = res.occupancy_tax_file.occupancy_tax_file_uuid;
      }
      if (res.priority) res.priority = res.priority.id;
      if (res.area) res.area = res.area.id;
      if (res.ac_types) {
        let arr = []
        res.ac_types.forEach(item => {
          arr.push(item.id)
        });
        res.ac_types = arr
      };
      if (res.heating_type) res.heating_type = res.heating_type.id;
      if (res.tv_service) res.tv_service = res.tv_service.id;
      if (res.coffee_maker_type)
        res.coffee_maker_type = res.coffee_maker_type.id;
      if (res.stove_type) res.stove_type = res.stove_type.id;
      if (res.toaster_type) res.toaster_type = res.toaster_type.id;
      if (res.grill_type) res.grill_type = res.grill_type.id;
      if (res.pool_type) res.pool_type = res.pool_type.id;
      if (res.bedrooms.length === 0) {
        for (let i = 0; i < res.bedroom_number; i++) {
          res.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      } else {
        for (let i = 0; i < res.bedrooms.length; i++) {
          res.bedrooms[i].floor_level = res.bedrooms[i].floor_level.id;
          let beds = res.bedrooms[i].beds;
          for (let j = 0; j < beds.length; j++) {
            res.bedrooms[i].beds[j].type = beds[j].type.id;
          }
        }
      }

      for (let i = 0; i < res.bathrooms.length; i++) {
        res.bathrooms[i].type = res.bathrooms[i].type.id;
      }
      // 关联浴室和房间
      if (res.bathrooms.length === 0) {
        for (let i = 0; i < res.bathroom_number; i++) {
          res.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      } else {
        for (let i = 0; i < res.bathrooms.length; i++) {
          if (res.bathrooms[i].bedroom) {
            res.bathrooms[i]["checked"] = true;
            res.bedrooms.forEach((bedroom, index) => {
              if (res.bathrooms[i].bedroom === bedroom.bedroom_uuid) {
                res.bathrooms[i]["index"] = index;
                this.map[i] = index;
              }
            });
          } else {
            res.bathrooms[i]["checked"] = false;
            res.bathrooms[i]["index"] = "";
          }
        }
      }

      // 删除不能传空值的key
      delete res.homeowner;
      // 删除该页面不会用到的key
      delete res.images;
      delete res.availabilities;
      delete res.rates;
      delete res.requirement;
      return res;
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.availabilities = res;
        setTimeout(() => {
          if (this.$refs.retaWeek) this.$refs.retaWeek.setRatesList()
        }, 3000);
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = this.changeDate(res);
        this.changeAction(res);
      });
    },

    save() {
      this.$refs.ListingForm.validate((valid) => {
        if (valid) {
          let listing = JSON.parse(JSON.stringify(this.data));
          Object.keys(this.map)
            .reverse()
            .forEach((key) => {
              // 反序删除bathrooms中的浴室
              let bedroom = listing.bedrooms[this.map[key]];
              if (bedroom.bathrooms) {
                // 房间已经有浴室对象
                bedroom.bathrooms.push(listing.bathrooms.splice(key, 1)[0]);
              } else {
                bedroom["bathrooms"] = listing.bathrooms.splice(key, 1);
              }
            });
          this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateListing(this.$route.params.id, listing)
              .then(
                (res) => {
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );

                  this.$store.commit("setPropertyInfor", true);
                  if (res.availabilities.length != 0 || res.rates.length != 0) {
                    this.$store.commit("setPropertyCalendar", true);
                  }
                  if (res.images.length != 0) {
                    this.$store.commit("setPropertyPhoto", true);
                  }
                  this.data = this.changeDate(res);
                  if (this.action === "publish" && !res.publish) {
                    // 未发布才发布
                    return apiPublishListing(this.$route.params.id).then(
                      (res) => {
                        this.$router.push({
                          name: "property-amenities",
                          params: { id: res.listing_id },
                        });
                        this.$message.success("Listing publish successfully");
                      }
                    );
                  } else if (
                    this.action === "deactivate" &&
                    (!res.deleted_at ||
                      res.deactivated_reason != this.deactivated_reason)
                  ) {
                    // 未禁用才禁用
                    return apiDeactivateListing(res.listing_id, {
                      deactivated_reason: this.deactivated_reason,
                    }).then((res) => {
                      this.$router.push({
                        name: "property-amenities",
                        params: { id: res.listing_id },
                      });
                      this.$message.success("Listing deactivate successfully");
                    });
                  } else if (
                    this.action === "hide" &&
                    (res.publish || res.deleted_at)
                  ) {
                    // 发布或禁用才变为隐藏
                    return apiHideListing(res.listing_id).then((res) => {
                      this.$message.success("Listing hide successfully");
                      this.$router.push({
                        name: "property-amenities",
                        params: { id: res.listing_id },
                      });
                    });
                  } else {
                    this.$router.push({
                      name: "property-amenities",
                      params: { id: res.listing_id },
                    });
                    this.$message.success(
                      "Listing information successfully modified"
                    );
                  }
                },
                (err) => {
                  this.$message.error("Listing information modify fail");
                }
              )
              .catch((err) => {
                this.$message.error(err);
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    changeStatus(selector) {
      var anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      document.body.scrollTop = anchor.offsetTop; // chrome
      document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollHeight = window.pageYOffset || document.body.scrollHeight;
      let height = document.documentElement.scrollHeight;
      if (scrollHeight > height - 1000) {
        this.btnFlag = false;
      } else {
        if (this.$route.params.id) {
          this.btnFlag = true;
        } else {
          this.btnFlag = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  computed: mapState({
    areas: (state) => state.areas,
    propertyPriorityList: (state) => state.enum.propertyPriorityList,
    acTypeList: (state) => state.enum.acTypeList,
    companyList: (state) => state.enum.companyList,
    heatingTypeList: (state) => state.enum.heatingTypeList,
    tvServiceList: (state) => state.enum.tvServiceList,
    coffeeMakerTypeList: (state) => state.enum.coffeeMakerTypeList,
    stoveTypeList: (state) => state.enum.stoveTypeList,
    toastTypeList: (state) => state.enum.toastTypeList,
    grillTypeList: (state) => state.enum.grillTypeList,
    poolTypeList: (state) => state.enum.poolTypeList,
    floorLevelList: (state) => state.enum.floorLevelList,
    bedTypeList: (state) => state.enum.bedTypeList,
    bathroomTypeList: (state) => state.enum.bathroomTypeList,
    tennisCourtType: (state) => state.enum.tennisCourtType,
    // 计算卧室的数量
    bedrooms() {
      return this.data.bedrooms.map((_, i) => {
        return {
          key: "Bedroom " + (i + 1),
          id: i,
        };
      });
    },
  }),
  created() {
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.propertyPriorityList.length === 0) {
      this.getPropertyPriorityList();
    }
    if (this.acTypeList.length === 0) {
      this.getACTypeList();
    }
    if (this.companyList.length === 0) {
      this.getCompanyList();
    }
    if (this.heatingTypeList.length === 0) {
      this.getHeatingTypeList();
    }
    if (this.tvServiceList.length === 0) {
      this.getTVServiceList();
    }
    if (this.coffeeMakerTypeList.length === 0) {
      this.getCoffeeMakerTypeList();
    }
    if (this.stoveTypeList.length === 0) {
      this.getStoveTypeList();
    }
    if (this.toastTypeList.length === 0) {
      this.getToastTypeList();
    }
    if (this.grillTypeList.length === 0) {
      this.getGrillTypeList();
    }
    if (this.poolTypeList.length === 0) {
      this.getPoolTypeList();
    }
    if (this.floorLevelList.length === 0) {
      this.getFloorLevelList();
    }
    if (this.bedTypeList.length === 0) {
      this.getBedTypeList();
    }
    if (this.bathroomTypeList.length === 0) {
      this.getBathroomTypeList();
    }
    if (this.tennisCourtType.length === 0) {
      this.getTennistCourtList();
    }
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.changeDate(
      //     JSON.parse(JSON.stringify(this.$store.state.listing))
      //   );
      //   this.changeAction(this.data);
      // } else {
      this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./Property.scss";

.checkbox-item {
  display: flex;
  flex-direction: row;
  width: 458px !important;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  margin-bottom: 0 !important;
  position: relative;

  .propety-title {
    position: absolute;
    top: -12px;
    font-size: 13px !important;
  }

}
.check-row {
  width: 100% !important;
  flex-direction: column !important;
}

.show-margin {
  margin-top: 0 !important;
}

/deep/ .el-form-item{
  margin-bottom: 0 !important;
}
</style>
